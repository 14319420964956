// tab
$(".tab-content").hide();
$(".tab-content:first").show();

//tab mode
$(".tabs-container li").click(function() {
    
  $(".tab-content").hide();
  var activeTab = $(this).attr("rel"); 
  $("#"+activeTab).fadeIn();		
  $(".tabs-container li").removeClass("active");
  $(this).addClass("active");
});